<template>
  <div class="w-full max-w-4xl mx-auto">
    <a-card
      :shadow="false"
      class="p-8 pb-2 pt-2"
    >
      <a-tabs
        :active="activeTab"
        @change="onTabChange"
      >
        <a-tab
          name="details"
          title="Personal Details"
        >
          <details-tab />
        </a-tab>
        <a-tab
          name="image"
          title="Profile Picture"
        >
          <profile-picture-tab />
        </a-tab>
        <a-tab
          name="password"
          title="Change Password"
        >
          <password-tab />
        </a-tab>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import DetailsTab from '@/components/account/tabs/details.vue';
import PasswordTab from '@/components/account/tabs/password.vue';
import ProfilePictureTab from '@/components/account/tabs/profile-picture.vue';
export default {
  components: {
    DetailsTab,
    PasswordTab,
    ProfilePictureTab
  },
  data: () => ({
    activeTab: 'details'
  }),
  methods: {
    onTabChange(tab) {
      this.activeTab = tab.name;
    }
  }
};
</script>