<template>
  <a-form @submit="onSubmit">
    <div class="grid grid-cols-6 gap-6 mb-4">
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="firstname" 
          :error="$v.form.firstname.$errors.length > 0"
        >
          First Name
        </a-label>
        <a-input
          id="firstname"
          v-model="form.firstname"
          name="firstname"
          :error="$v.form.firstname.$errors.length > 0"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="lastname" 
          :error="$v.form.lastname.$errors.length > 0"
        >
          Last Name
        </a-label>
        <a-input
          id="lastname"
          v-model="form.lastname"
          name="lastname"
          :error="$v.form.lastname.$errors.length > 0"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <a-label for="email">
          Email Address
        </a-label>
        <a-input
          id="email"
          v-model="form.email"
          type="email"
          name="email"
          disabled
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="company" 
          :error="$v.form.company.$errors.length > 0"
        >
          Company
        </a-label>
        <a-input
          id="company"
          v-model="form.company"
          name="company"
          :error="$v.form.company.$errors.length > 0"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="title" 
          :error="$v.form.title.$errors.length > 0"
        >
          Title
        </a-label>
        <a-input
          id="title"
          v-model="form.title"
          name="title"
          :error="$v.form.title.$errors.length > 0"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="website" 
          :error="$v.form.website.$errors.length > 0"
        >
          Website
        </a-label>
        <a-input
          v-model="form.website"
          type="url"
          name="website"
          :error="$v.form.website.$errors.length > 0"
        />
      </div>
    </div>
    <div
      class="py-4 pb-0 border-t border-gray-200"
    >
      <div class="flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-2/12"
          @click="onSubmit"
        >
          Save
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
import { error } from '@/utils/helpers';
import { mapActions, mapGetters } from 'vuex';
import { VuelidateMixin } from '@vuelidate/core';
import { required, minLength, maxLength, url } from '@vuelidate/validators';
export default {
  mixins: [VuelidateMixin],
  emits: ['submit'],
  data: () => ({
    loading: false,
    form: {
      firstname: '',
      lastname: '',
      company: '',
      title: '',
      website: ''
    }
  }),
  validations: {
    form: {
      firstname: {
        required,
        minLength: minLength(2)
      },
      lastname: {
        required,
        minLength: minLength(2)
      },
      company: {
        maxLength: maxLength(100)
      },
      title: {
        maxLength: maxLength(100)
      },
      website: {
        url,
        maxLength: maxLength(100)
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isUser'])
  },
  watch: {
    user: {
      immediate: true,
      handler: 'onUserSet'
    }
  },
  methods: {
    ...mapActions('account', ['details']),
    onUserSet() {
      if (this.isUser) {
        this.form = { ...this.user };
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;

      try {
        await this.details(this.form);
        this.$notify('success', 'Account Details Saved.');
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>