
<template>
  <div class="grid grid-cols-3 gap-6">
    <div class="col-span-3">
      <div class="mt-1 flex items-center">
        <span class="inline-block bg-gray-100 rounded-full overflow-hidden">
          <profile-image
            :user="user"
            size="2xl"
          >
            <template #placeholder>
              <span class="inline-block">
                <a-icon
                  variant=""
                  name="user"
                  size="3x"
                  class="h-full w-full text-gray-200"
                />
              </span>
            </template>
          </profile-image>
        </span>
        <a-file-upload
          ref="uploader"
          auto
          custom-upload
          class="ml-5"
          accept="image/*"
          :max-file-size="5000000"
          :disabled="loading"
          :loading="loading"
          @uploader="onUploaded"
        >
          <template #label>
            <span>Change</span>
          </template>
        </a-file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImage from '@/components/users/profile-image.vue';
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from '@/utils/lodash';
import Uploader from '@/services/uploader.js';
export default {
  components: {
    ProfileImage
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('account', ['savePicture']),
    async onUploaded({ files }) {
      let file = files[0] || null;

      if (isEmpty(file)) {
        return;
      }

      this.loading = true;

      try {
        const uploader = new Uploader();
        const key = await uploader.upload(file);

        await this.savePicture(key);
      } catch (e) {
        this.$notify('danger', 'Failed Uploading Image.');
        console.error(e);
      } finally {
        this.loading = false;
        this.$refs.uploader.clear();
      }
    }
  }
};
</script>