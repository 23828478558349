<template>
  <a-form @submit="onSubmit">
    <div class="grid grid-cols-6 gap-6 mb-4">
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="password" 
          :error="$v.password.$errors.length > 0"
        >
          Password
        </a-label>
        <a-input
          id="password"
          v-model="password"
          name="password"
          type="password"
          :readonly="loading"
          required
          :error="$v.password.$errors.length > 0"
        />
        <div v-if="$v.password.$errors.length > 0">
          <a-help error>
            Password must be at least 8 characters long.
          </a-help>
        </div>
      </div>


      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="confirm"
          :error="$v.confirm.$errors.length > 0 || confirmError"
        >
          Confirm Password
        </a-label>
        <a-input
          id="confirm"
          v-model="confirm"
          type="password"
          :readonly="loading"
          required
          :error="$v.confirm.$errors.length > 0 || confirmError"
        />
        <div v-if="$v.confirm.$errors.length > 0 || confirmError">
          <a-help error>
            Password Confirmation Must Match.
          </a-help>
        </div>
      </div>
    </div>
    <div
      class="py-4 pb-0 border-t border-gray-200"
    >
      <div class="flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-2/12"
          @click="onSubmit"
        >
          Save
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
import { error } from '@/utils/helpers';
import { mapActions } from 'vuex';
import { VuelidateMixin } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
export default {
  mixins: [VuelidateMixin],
  emits: ['submit'],
  data: () => ({
    loading: false,
    password: '',
    confirm: '',
    confirmError: false
  }),
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    confirm: {
      required,
      minLength: minLength(8)
      // Doesn't work yet
      // sameAs: sameAs('password')
    }
  },
  methods: {
    ...mapActions('account', ['savePassword']),
    onReset() {
      this.$v.$reset();
      this.confirm = '';
      this.password = '';
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;

      try {
        await this.savePassword({
          password: this.password,
          password_confirmation: this.confirm
        });
        this.$notify('success', 'Password Saved.');
        this.onReset();
      } catch (e) {
        this.$notify('danger', error(e));
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>